@page {
  size: US-Letter;
  margin: .5in .5in;
  
  @top-left {
    content: "Designed Results";
  }

  @bottom-left {
    content: element(footer);
  }
}

@media print {
  .mapping-sequence, code, ul {
    page-break-inside: avoid;
  }
  .no-print {
    display: none;
  }
}
