pre {
  margin: 0;
}

code {
  font-family: 'Roboto Mono', monospace;
}

.gfm li>p {
  margin: 0;
}


.parameters-In td, .parameters-Out td {
  vertical-align: top;
}

.expanding-section:hover {
  cursor: pointer;
  background-color: var(grey200)
}